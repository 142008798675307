
import "bootstrap/dist/css/bootstrap.min.css";
import Vue from "vue";
import Home from "./views/Home/Home.vue";

export default Vue.extend({
  name: "App",
  components: {
    Home,
  },

  data: () => ({
    //
  }),
});
