import { render, staticRenderFns } from "./ListaImpFederais.vue?vue&type=template&id=eff1ad96&scoped=true&"
import script from "./ListaImpFederais.vue?vue&type=script&lang=js&"
export * from "./ListaImpFederais.vue?vue&type=script&lang=js&"
import style0 from "./ListaImpFederais.vue?vue&type=style&index=0&id=eff1ad96&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eff1ad96",
  null
  
)

export default component.exports