<template>
  <v-app>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="precosMascarados"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.codigo_loja }}</td>
            <td>{{ item.nome_loja }}</td>
            <td>{{ item.codigo_finalizadora }}</td>
            <td>{{ item.nome_finalizadora }}</td>
            <td>{{ item.qtd_finalizacoes }}</td>
            <td>{{ item.venda_total }}</td>
            <td>{{ item.venda_troco }}</td>
            <td>{{ item.diferenca_venda }}</td>
          </tr>
        </template>
        <template slot="body.append">
          <tr class="blue--text">
            <th class="title">Totais</th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title">{{ sumField("qtd_finalizacoes", items) }}</th>
            <th class="title">{{ sumField("venda_total", items) }}</th>
            <th class="title">{{ sumField("venda_troco", items) }}</th>
            <th class="title">{{ sumField("diferenca_venda", items) }}</th>
          </tr>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>

<script>
import { maskAmount, maskMoney, maskQtd } from "@/utils/masks";

export default {
  data() {
    return {
      headers: [
        { text: "Cód", value: "codigo_loja" },
        { text: "Loja", value: "nome_loja" },
        { text: "Cod. Fin.", value: "codigo_finalizadora" },
        { text: "Finalizadora", value: "nome_finalizadora" },
        { text: "Qtd", value: "qtd_finalizacoes" },
        { text: "Entrada Total", value: "venda_total" },
        { text: "Troco Total", value: "venda_troco" },
        { text: "Total", value: "diferenca_venda" },
      ],
    };
  },
  computed: {
    precosMascarados() {
      return this.relatorio.map((venda) => {
        return {
          ...venda,
          venda_total: maskMoney(venda.venda_total),
          venda_troco: maskMoney(venda.venda_troco),
          qtd_finalizacoes: maskQtd(venda.qtd_finalizacoes),
          diferenca_venda: maskMoney(venda.diferenca_venda),
        };
      });
    },
    relatorio() {
      return this.$store.state.relatorio.relatorioPainelVendasFinalizadoras;
    },
  },
  methods: {
    sumField(field) {
      let total = 0;
      if (this.relatorio) {
        total = this.relatorio.reduce(
          (accumulator, item) => accumulator + Number(item[field]),
          0
        );
      }
      return maskQtd(total);
    },
  },
};
</script>
