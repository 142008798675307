import Vue from "vue";
import axios from "axios";
import router from "@/router";
// axios.defaults.baseURL = "https://rk-nuvem-back.vercel.app/api";
// axios.defaults.baseURL = "http://localhost:3000/api";
axios.defaults.baseURL = "https://rknuvem.com.br:3000/api";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("access_token");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios;
  },
});
