<template>
  <v-app>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="precosMascarados"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.codigo }}</td>
            <td>{{ item.nome_loja }}</td>
            <td>{{ item.caixa }}</td>
            <td>{{ item.qtd_clientes }}</td>
            <td>{{ item.venda }}</td>
            <td>{{ item.venda_custo }}</td>
            <td>{{ item.venda_liquida }}</td>
          </tr>
        </template>
        <template slot="body.append">
          <tr class="blue--text">
            <th class="title">Totais</th>
            <th class="title"></th>
            <th class="title"></th>
            <th class="title">{{ sumField("qtd_clientes", items) }}</th>
            <th class="title">{{ sumField("venda", items) }}</th>
            <th class="title">{{ sumField("venda_custo", items) }}</th>
            <th class="title">{{ sumField("venda_liquida", items) }}</th>
          </tr>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>

<script>
import { maskAmount, maskMoney, maskQtd } from "@/utils/masks";

export default {
  data() {
    return {
      headers: [
        { text: "Cód", value: "codigo" },
        { text: "Loja", value: "nome_loja" },
        { text: "Caixa", value: "caixa" },
        { text: "Qtd Clientes", value: "qtd_clientes" },
        { text: "Venda Total", value: "venda" },
        { text: "Custo Total", value: "venda_custo" },
        { text: "Venda Líquida", value: "venda_liquida" },
      ],
    };
  },
  computed: {
    precosMascarados() {
      return this.relatorio.map((venda) => {
        return {
          ...venda,
          venda: maskMoney(venda.venda),
          venda_custo: maskMoney(venda.venda_custo),
          venda_liquida: maskMoney(venda.venda_liquida),
          qtd_clientes: maskQtd(venda.qtd_clientes),
        };
      });
    },
    relatorio() {
      return this.$store.state.relatorio.relatorioPainelVendasCaixas;
    },
  },
  methods: {
    sumField(field) {
      let total = 0;
      if (this.relatorio) {
        total = this.relatorio.reduce(
          (accumulator, item) => accumulator + Number(item[field]),
          0
        );
      }
      return maskQtd(total);
    },
  },
};
</script>
